import React, { useState } from 'react'
import './Dropdown.css'
import { Link } from 'react-router-dom';
import { FaCaretDown } from "react-icons/fa";

const Dropdown = () => {
    
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () =>{
        setIsOpen(!isOpen);
    }

    // ${isOpen: ? 'rotate-180' : ''}

  return (
    <div className='dropdown flex justify-center'>
        <button className= "flex flex-row items-center gap-2" onClick={toggleOpen}>Services <span className={`${isOpen ? 'rotate-180' : ''}`} ><FaCaretDown /></span> </button>
        {
            isOpen && (
                <div className="dropdown-menu">
                    <Link onClick={toggleOpen} className='link' to='/ua'> User Acquisition </Link>
                    <Link onClick={toggleOpen} className='link' to='/pa'> Programmatic Advertising </Link>
                </div>
            )
        }
        
    </div>
  )
}

export default Dropdown