import React from 'react'
import Blog from '../Blog/Blog'

const BlogPage = () => {
  return (
    <div>
        <Blog />
    </div>
  )
}

export default BlogPage