import React from 'react'
import './ServiceCard.css'

const ServiceCard = (props:any) => {
  return (
    <div className='service-card' data-aos="fade-up" data-aos-delay={props.AOSdelay}>
        <div className="service-icon">
            <img src={props.img} alt={''} />
        </div>
        <div className="service-name">
            <p>{props.title}</p>
        </div>
        <div className="service-desc">
            <p> {props?.desc} </p>
        </div>
    </div>
  )
}

export default ServiceCard