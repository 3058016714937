import React from 'react'
import './ProductSection.css'
import uaImg from '../../images/UserAcquisition.svg'
import paImg from '../../images/ProgrammaticAdverting.svg'
import mpbImg from '../../images/MediaPlanningBuying.svg'
import { FaArrowRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const ProductSection = () => {

    const productCards =[
        {
          title: 'User Acquisition',
          img: uaImg,
          desc: "Engage users and achieve lasting results. Sapien Ads leverages the power of storytelling to connect with your target audience, driving in-app conversions and user retention through a brand-focused approach.",
          href: "/ua"
        },
        {
          title: "Programmatic Adverting",
          img: paImg,
          desc: "Unleash the power of data-driven mobile advertising. Our DSP streamlines your buying and bidding, unlocks real-time auctions for optimal efficiency, and delivers precise targeting to reach your ideal customers globally. Take control and achieve exceptional results.",
          href: "/pa"
        },
        {
          title: "Media planning & buying",
          img: mpbImg,
          desc: "Coming soon...",
          href: "/"
        }
      ]
  return (
    <div className="product-section">
        <div className="product-section-title" >
            Our Products
            {/* <ContactButton /> */}
        </div>
        <div className="product-cards" >
          <Link to='/ua' >
          <div className="pCard">
            <div className="pImg">
                <img src={productCards[0].img} alt='' width={320}/>
            </div>
            <div className="pDetail">
                <p className="product-name"> {productCards[0].title} </p>
                <p className="product-desc"> {productCards[0].desc} </p>
                <div className="arrow">
                  Learn More <FaArrowRight /> 
                </div>
            </div>
          </div>
          </Link>

          <Link to='/pa' >
          <div className="pCardInv" >
            <div className="pImg">
                <img src={productCards[1].img} alt='' width={320}/>
            </div>
            <div className="pDetail">
                <p className="product-name"> {productCards[1].title} </p>
                <p className="product-desc"> {productCards[1].desc} </p>
                <div className="arrow">
                  Learn More <FaArrowRight />
                </div>
            </div>
          </div>
          </Link>

          <div className="pCard" >
            <div className="pImg">
                <img src={productCards[2].img} alt='' width={320}/>
            </div>
            <div className="pDetail">
                <p className="product-name"> {productCards[2].title} </p>
                <p className="product-desc"> {productCards[2].desc} </p>
                {/* <div className="arrow">
                  Learn More <FaArrowRight />
                </div> */}
            </div>
          </div>
        </div>
    </div>
  )
}

export default ProductSection