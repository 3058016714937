import React from 'react'
import './PPHeroSection.css'

const PPHeroSection = (props:any) => {
  return (
    <div className="product-page-hero-section">
        <div className="product-page-heading">
            <p> {props.title} </p>
            <p className="product-page-sub-heading"> {props.subhead} </p>
        </div>
        <div className="product-page-hero-image">
            <img src={props.img} alt='' />
        </div>
    </div>
  )
}

export default PPHeroSection