import React from 'react'
import PPHeroSection from '../PPHeroSection/PPHeroSection'
import userAcquisitionImg from '../../images/UserAcquisition.svg'
import UAPPBody from '../UAPPBody/UAPPBody'

const UAPage = () => {
  return (
    <div>
      <PPHeroSection title="User Acquisition" subhead="Find your next paying customer" img={userAcquisitionImg} />
      <UAPPBody />
    </div>
  )
}

export default UAPage