import React from 'react'
import PPHeroSection from '../PPHeroSection/PPHeroSection'
import programmaticAdvertingImg from '../../images/ProgrammaticAdverting.svg'
import PAPPBody from '../PAPPBody/PAPPBody'

const PAPage = () => {
  return (
    <div>
        <PPHeroSection title="Programmatic Adverting"  img={programmaticAdvertingImg} />
        <PAPPBody />
    </div>
  )
}

export default PAPage