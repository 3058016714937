import React from 'react'
import HeroImage from '../HeroSection/HeroSection'
import StorySection from '../StorySection/StorySection'
import ProductSection from '../ProductSection/ProductSection'
import ContactSection from '../ContactSection/ContactSection'

const HomePage = () => {
  return (
    <div>
      <HeroImage />
      <StorySection />
      <ProductSection />
      <ContactSection />
    </div>
  )
}

export default HomePage