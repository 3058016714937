import React from 'react'
import './ContactButton.css'
import { Link } from 'react-router-dom'

const ContactButton = () => {
  return (
    <div className='contact-button'>
        <Link to='/contactus'>
            Let's Talk
        </Link>
    </div>
  )
}

export default ContactButton