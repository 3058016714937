import React, { useState } from 'react'
import emailjs from '@emailjs/browser'
import './ContactUsPage.css'

declare global {
    namespace JSX {
      interface IntrinsicElements {
        'lord-icon': any; // Use a more specific type if available
      }
    }
  }

const ContactUsPage = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [buttonHover, setButtonHover] = useState(false);

    // const dbuser = process.env.NEXT_PUBLIC_DB_USER;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        

        const sid = process.env.REACT_APP_S_ID || "";
        const tid = process.env.REACT_APP_T_ID || "";
        const pk = process.env.REACT_APP_P_KEY || "";
        
        // const sid = "service_2e4o1qn";
        // const tid = "template_zk78ka7";
        // const pk = "hJwYXmM9g4CzptBXk";

        // const strSid = sid?.toString;
        
        const templateParams = {
            from_name: name,
            from_email: email,
            to_name: "Praty",
            message: message,
        };


        emailjs.send( sid, tid, templateParams, pk)
            .then((response) =>{
                console.log("Email sent successfully", response);
                setName('');
                setEmail('');
                setMessage('');
            })
            .catch((error) =>{
                console.error('Error sending email: ', error);
            })
        
    }

    // const handleClicklog = () => {
    //     const s_id = process.env.REACT_APP_S_ID;
    //     console.log("s_ID = ", s_id);
    // }

  return (
    <div className="contact-us-main">
        <div className="contact-us-heading">
            Lets Work Together
        </div> 
        {/* <button onClick={handleClicklog}>Click me</button> */}
        <div className="contact-us-form">
            <p className="form-heading">Contact Us</p>
            {/* onSubmit={handleSubmit} */}
            <form onSubmit={handleSubmit} className='emailForm grid grid-cols-1 mx-auto'>
                <input 
                    type='text' 
                    placeholder='Your Name' 
                    value={name} 
                    onChange={(e)=> setName(e.target.value)}
                    className='mt-5' />

                <input 
                    type='email' 
                    placeholder='Your Email' 
                    value={email} 
                    onChange={(e)=> setEmail(e.target.value)}
                    className='mt-5' />

                <textarea
                    cols={30}
                    rows={10}
                    value={message}
                    onChange={(e)=> setMessage(e.target.value)}
                    placeholder='Your Message'
                    className='mt-5 border-solid border-2 border-gray-600'
                >
                </textarea>
                {/* <button className="text-black" type='submit'>Send</button> */}
                <button type='submit' onMouseOver={()=>{
                    setButtonHover(true);
                }} 
                    onMouseLeave={()=>{
                        setButtonHover(false);
                    }}
                className='submit-button mt-5 border-solid border-2 border-gray-600' >
                    {
                        buttonHover ? (
                            <>
                            <script src="https://cdn.lordicon.com/lordicon.js"></script>
                                <lord-icon
                                    src="https://cdn.lordicon.com/aycieyht.json"
                                    trigger="click"
                                    style={{width:"250px",height:"250px"}}>
                                </lord-icon>
                            </>
                        ) : (
                            <>Send</>
                        )
                    }
                    
                </button>
            </form>
        </div>   
    </div>
  )
}

export default ContactUsPage