import React, { useState } from 'react'
import './Navbar.css'
import Logo from '../Logo/Logo'
import { NavLink } from 'react-router-dom'
import { Menu, X } from 'lucide-react'
import Dropdown from '../Dropdown/Dropdown'
 
const NavLinks = () => {

  return(
    <>
          <NavLink className='nav-link' to='/' >Home</NavLink>
          <Dropdown />
          <NavLink className='nav-link' to='/blog' >Blogs</NavLink>
          {/* <NavLink className='nav-link' to='/contactus' >Contact Us</NavLink> */}
    </>
  )
}

const Navbar = () => {

  const [isOpen, setIsOpen] = useState(false);
  

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  }

  return (
    <header className="navbar text-xl min-h-28 sticky top-0 z-[20] flex-wrap mx-auto flex w-full items-center justify-between md:justify-around p-8" >
        <Logo />
        <nav className="w-1/3 justify-end">
          <div className="hidden w-full justify-between md:flex">
            <NavLinks />
          </div>
          <div className="md:hidden flex justify-end">
            <button onClick={toggleNavbar}> {isOpen ? <X/> : <Menu /> } </button>
          </div>
        </nav>
        { isOpen && (
          <div className='flex gap-2 basis-full flex-col items-center'>
            <NavLinks />
          </div>
        )
        }
    </header>
  )
}

export default Navbar