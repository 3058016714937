import React from 'react'
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';
import heroImage from '../../images/StoneAge6.jpeg'
import './HeroSection.css'
import ContactButton from '../ContactButton/ContactButton';

const HeroImage = () => {
  return (
    <div className="hero-section flex flex-col items-center justify-center p-4">
        <div className="hero-heading flex flex-col text-left items-center">
            <p className='main-head'>Discover the power of razor sharp targeting helping <span> maximize conversions globally</span></p>
            <p className="hero-sub-heading">Chuck the ancient style of performance campaign with Sapien Ads</p>
            <div className='cnt-btn hidden'>
              <ContactButton />
            </div>
            <div className="hidden social-icons m-4 self-start gap-8" >
              <Link to='/'><FaInstagram /></Link>
              <Link to='/'><FaFacebook /></Link>
            </div>
        </div>
        <div className="hero-image flex justify-center items-center m-4">
          <img src={heroImage} alt="" />
        </div>
        <div className="contact-area flex flex-col w-full justify-start">
            <div className='cnt-btn'>
              <ContactButton />
            </div>
            <div className="social-icons flex flex-row m-4 self-start gap-8" >
              <Link to='/'><FaInstagram /></Link>
              <Link to='/'><FaFacebook /></Link>
            </div>
        </div>
    </div>
  )
}

export default HeroImage