import React from 'react'
import { Link } from 'react-router-dom';
// import logo from '../../images/sapienAdsLogo.jpg'

const Logo = () => {
  return (
    <div className="logo h-16 flex justify-center items-center" >
      <Link to='/'>
        {/* <img src={logo} alt="" /> */}
        <p className="brand-name text-3xl font-semibold">Sapien Ads.</p>
      </Link>
    </div>
  )
}

export default Logo;