import React from 'react'
import './StorySection.css'
import globeImg from '../../images/globe.png';
import qualityImg from '../../images/quality.png';
import securityImg from '../../images/security.png';
import supportImg from '../../images/support.png';
import revenueImg from '../../images/revenue.png';
import StoryCard from '../StoryCard/StoryCard';

const StorySection = () => {

    const storyCards = [
        {
          title: "Global Coverage",
          desc: "Reach your audiences globally, efficiently and effectively",
          img: globeImg
        },
        {
          title: "Quality Control",
          desc: "We have got you covered by delivering the best quality traffic",
          img: qualityImg
        },
        {
          title: "Fraud Detection",
          desc: "Quality traffic with 100% reliable and genuine networks",
          img: securityImg
        },
        {
          title: "Dedicated support",
          desc: "Get dedicated support 24/7 so that the campaign delivery is smooth",
          img: supportImg
        },
        {
          title: "Revenue Growth",
          desc: "Grow revenue with Sapien ad’s exclusive quality traffic",
          img: revenueImg
        },
      ]
  return (
    <div className="story-section flex flex-col">
        <div className="story-title">
          Why us?
        </div>
        <div className="story-text">
          Sapien ads employs extensive data analytics to precisely target and optimize ads. Our data-driven strategy ensures adaptable solutions for 
          achieving success in digital advertising.
        </div>
        <div className="story-list">
            <StoryCard img={storyCards[0].img} desc={storyCards[0].desc} title={storyCards[0].title} />
            <StoryCard img={storyCards[1].img} desc={storyCards[1].desc} title={storyCards[1].title} />
            <StoryCard img={storyCards[2].img} desc={storyCards[2].desc} title={storyCards[2].title} />
        </div>
        <div className="story-list">
            <StoryCard img={storyCards[3].img} desc={storyCards[3].desc} title={storyCards[3].title} />
            <StoryCard img={storyCards[4].img} desc={storyCards[4].desc} title={storyCards[4].title} />
        </div>
    </div>
    // <div>
    //     <StoryCard img={storyCards[0].img} desc={storyCards[0].desc} title={storyCards[0].title} />
    //     {/* <StoryCard img={storyCards[0].img} desc={storyCards[0].desc} title={storyCards[0].title} AOSdelay="100"/> */}
    // </div>
  )
}

export default StorySection