import React from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import UAPage from './components/UAPage/UAPage';
import ContactUsPage from './components/ContactUsPage/ContactUsPage';
import Footer from './components/Footer/Footer';
import PAPage from './components/PAPage/PAPage';
import BlogPage from './components/BlogPage/BlogPage';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';

function App() {

  const router = createBrowserRouter([
    {
      path: '/',
      element: <><Navbar /><HomePage /><Footer /></>
    },
    {
      path: '/ua',
      element: <><Navbar /><UAPage /><Footer /></>
    },
    {
      path: '/pa',
      element: <><Navbar/><PAPage/><Footer/></>
    },
    {
      path: '/blog',
      element: <><Navbar/><BlogPage /><Footer/></>
    },
    {
      path: '/contactus',
      element: <><Navbar/><ContactUsPage /><Footer /></>
    },
    {
      path: '/privacy-policy',
      element: <><Navbar/><PrivacyPolicy /><Footer /></>
    }
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
