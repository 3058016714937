import React from 'react'
import './StoryCard.css'

const StoryCard = (props:any) => {
    // data-aos="fade-up" data-aos-delay={props.AOSdelay}
  return (
    <div className="story-card flex flex-col justify-center items-center" >
        <div className="icon-container">
            <img className="story-icon absolute top-0 left-0" src={props.img} alt="" />
            {/* <Image className="story-icon" src={props.img} alt={''} /> */}
            <div className="curve-rect"></div>
        </div>
        <div className="story-card-title"> {props.title} </div>
        <div className="story-card-desc"> {props.desc} </div>
    </div>
  )
}

export default StoryCard 