import React from 'react'
import './ContactSection.css'
import ContactButton from '../ContactButton/ContactButton'
import contactImg from '../../images/contact.svg'

const ContactSection = () => {
  return (
    <div className="contact-section">
        <div className="contact-text">
            <p className='contact-heading'>Contact Us</p>
            <p className='contact-desc' >Ready to get started? Contact us today.</p>
            <ContactButton />
        </div>
        <div className="contact-img">
            <img src={contactImg} alt='' width={350}/>
        </div>
    </div>
  )
}

export default ContactSection