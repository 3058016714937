import React from 'react'
import './UAPPBody.css'
import targetUserImg from '../../images/TargetUserIcon.svg'
import protectionImg from '../../images/ProtectionIcon.svg'
import usersImg from '../../images/UsersIcon.svg'
import retentionImg from '../../images/RetentionIcon.svg'
import adFormatImg from '../../images/AdFormat.svg'
import ServiceCard from '../ServiceCard/ServiceCard'

const UAPPBody = () => {
    const services = [
        {
          title: "Precise targeting",
          image: targetUserImg
        },
        {
          title: "Ad Fraud protection",
          image: protectionImg
        },
        {
          title: "Quality Users",
          image: usersImg
        },
        {
          title: "Maintain Retention",
          image: retentionImg
        },
        {
          title: "Ad Formats",
          image: adFormatImg
        }
      ]
  return (
    <div className="product-page-body">
        <div className="product-page-desc">
          Acquire quality users at scale with Sapien Ads. Connect with your audiences in a storytelling format, we use brand first performance approach to drive in app conversions and retention.
        </div>
        <div className="service-cards">
          <ServiceCard title={services[0].title} img={services[0].image} AOSdelay="100" />
          <ServiceCard title={services[1].title} img={services[1].image} AOSdelay="300" />
          <ServiceCard title={services[2].title} img={services[2].image} AOSdelay="500" />
        </div>
        <div className="service-cards">
          <ServiceCard title={services[3].title} img={services[3].image} AOSdelay="700" />
          <ServiceCard title={services[4].title} img={services[4].image} AOSdelay="900" />
        </div>
    </div>
  )
}

export default UAPPBody