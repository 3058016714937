import React from 'react'
import './Footer.css'
import Logo from '../Logo/Logo'
import { Link } from 'react-router-dom'
import { FaFacebook, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <div className='footer flex flex-col gap-4'>
        <div className="upper-footer flex flex-col">
            <div className="footer-logo">
                <Logo />
            </div>
            <div className="section">
                <p className="section-title">
                    Useful Links
                </p>
                <div className="links">
                    <p>Our Services</p>
                    <p><Link to='/'>About us</Link></p>
                    <p>Blogs</p>
                </div>
            </div>
            <div className="section">
                <p className="section-title">
                    Contact Info
                </p>
                <div className="links">
                    <p>connect@sapienads.com</p>
                    {/* <p>+91-1234567890</p> */}
                    <p className="icons flex flex-row gap-3 text-2xl">
                        <FaFacebook />
                        <FaInstagram />
                    </p>
                </div>
            </div>
        </div>
        <div className="lower-footer flex flex-row gap-6 items-center underline">
            <p><Link to='/privacy-policy'>Privacy Policy</Link></p>
        </div>
    </div>
  )
}

export default Footer