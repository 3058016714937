import React from 'react'
import './PAPPBody.css'
import biddingImg from '../../images/bidding.svg'
import targetImg from '../../images/TargetUserIcon.svg'
import globeImg from '../../images/globe.svg'
import ServiceCard from '../ServiceCard/ServiceCard'

const PAPPBody = () => {
    const services = [ 
        {
          title: "Real time bidding",
          desc: "Participatein real time auction for inventory, ensuring lower costs and efficiency",
          image: biddingImg
        },
        {
          title: "Precise targeting",
          desc: "Leverage our robust data sources for accurate ads targeting and reaching our your ideal customers",
          image: targetImg
        },
        {
          title: "Global reach",
          desc: "Connect with a global mobile audience and expand your brand’s visibility",
          image: globeImg
        },
      ]
  return (
    <div className="pa-pp-body">
      <div className="pa-pp-desc">Our DSP is a powerful tool designed to curate your mobile buying and bidding system</div>
      <p className="pa-pp-offer" >Our DSP offers below</p>
      <div className="service-cards">
          <ServiceCard title={services[0].title} img={services[0].image} desc={services[0].desc} AOSdelay="100" />
          <ServiceCard title={services[1].title} img={services[1].image} desc={services[1].desc} AOSdelay="300" />
          <ServiceCard title={services[2].title} img={services[2].image} desc={services[2].desc} AOSdelay="500" />
        </div>
    </div>
  )
}

export default PAPPBody