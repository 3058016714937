import React from 'react'
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
  return (
    <div className='privacy-policy text-left'>
        <div className="head">
            <h1 className="title">Privacy Policy</h1>
            <p className='text-xl font-medium'>Effective date: May 5, 2024</p>
            <br />
            <p className='pp-text'>
            This Privacy Policy describes how Sapien Ads collects, uses, and shares personal information when you visit or make a purchase from our website www.sapienads.com
            </p>
        </div>
        <br />
        <br />
        <div className="pp-body">
            <h1 className="title">Personal Information We Collect</h1>
            <p className="pp-text">
            When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as "Device Information".
                <br />
                <br />
                We collect Device Information using the following technologies:
                <ul className='u-list'>
                    <li>"Cookies" are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.</li>
                    <li>"Log files" track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
                    <li>"Web beacons", "tags", and "pixels" are electronic files used to record information about how you browse the Site.</li>
                </ul>
                <br />
                Additionally, when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number. We refer to this information as "Order Information".
            </p>
            <br />
            <br />
            <h1 className="title">How We Use Your Personal Information</h1>
            <p className="pp-text">
                We use the Information that we collect generally to fulfill any queries placed through the Site and also
                <ul className="ul-list">
                    <li>Communicate with you;</li>
                    <li>Screen our queries for potential risk or fraud; and</li>
                    <li>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
                </ul>
                We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).
            </p>
            <br />
            <br />
            <h1 className="title">Sharing Your Personal Information</h1>
            <p className="pp-text">
            We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Google Analytics to help us understand how our customers use the Site - you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
            </p>
            <br />
            <br />
            <h1 className="title">Your Rights</h1>
            <p className="pp-text">
            If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
            </p>
            <br />
            <br />
            <h1 className="title">Changes</h1>
            <p className="pp-text">
            We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
            </p>
            <br />
            <br />
            <h1 className='title'>Contact Us</h1>
            <p className="pp-text">
            For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at connect@sapienads.com
            </p>
        </div>
    </div>
  )
}

export default PrivacyPolicy